import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Input } from 'react-native-elements';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import moment from 'moment';
import { isWeb } from '../../../common/utils/platform.utils';

const WEBCSS = isWeb() ? React.lazy(() => import('../web-picker.css')) : null;
const formatDate = (date) => moment(date).format('YYYY-MM-DD');

export const WebDatePicker = ({
    label,
    date,
    minimumDate,
    maximumDate,
    onChange,
}) => {
    const textFieldRef = useRef(null);
    const initialDate = date || moment().toDate();
    const formattedDate = date && formatDate(date);

    return (
        <>
            <React.Suspense fallback={<></>}>
                {<WEBCSS />}
            </React.Suspense>
            <DatePicker
                selected={initialDate}
                onChange={(date) => { onChange(formatDate(date)) }}
                minDate={minimumDate}
                maxDate={maximumDate}
                customInput={
                    <Input
                        label={label}
                        ref={textFieldRef}
                        placeholder={i18n.t('common.selectDate')}
                        onFocus={() => {
                            textFieldRef.current.blur();
                        }}
                        value={formattedDate}
                        style={{ width: '100%', maxWidth: '100%', outlineStyle: 'none' }}
                        rightIcon={{
                            name: 'keyboard-arrow-down',
                        }}
                    />
                }
            />
        </>
    );
};

WebDatePicker.propTypes = {
    label: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    minimumDate: PropTypes.instanceOf(Date),
    maximumDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
};
