import { View, useWindowDimensions, Alert } from 'react-native';
import { Button, CheckBox, Icon, Input, Text } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Dropdown } from 'react-native-element-dropdown';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { webViewDesignSupport } from '../../../common/utils/secure-store.utils';
import { DatePickerNative } from '../../home/components/date-picker.component';
import { useToast } from 'react-native-toast-notifications';
import { api } from '../../../../App.context';
import { isWeb } from '../../../common/utils/platform.utils';
import { SendMoneyOutAccount } from '../../../common/ui/components/send-money-out.component';
import { WebDatePicker } from '../../home/components/web-date-picker.component';
import { CustomHeaderComponent } from '../../../navigation/screen-options.component';

export const FundMyAccountDetail = () => {
    const [externalAccounts, setExternalAccounts] = useState(null);
    const toast = useToast();
    const {
        params: { accounts, selectedExternalAccounts },
    } = useRoute();

    const fetchExternalAccountsAccounts = () => {
        api.get('/external_accounts')
            .then((r) => setExternalAccounts(r.external_accounts))
            .catch((err) => {
                isWeb()
                    ? toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    })
                    : Alert.alert(i18n.t('common.errorTitle'), err.message);
            });
    };

    useEffect(() => fetchExternalAccountsAccounts(), []);

    return (
        <>
            <CustomHeaderComponent maintitle={i18n.t('pages.moveMoney.fundMyAccount.fundMyAccountDetails.title')} subtitle={i18n.t('common.details')} />
            <ModalLayout>
                <FundTransferForm accounts={accounts} externalAccounts={externalAccounts} selectedExternalAccounts={selectedExternalAccounts} />
            </ModalLayout>
        </>
    );
};

const FundTransferForm = ({ accounts, externalAccounts, selectedExternalAccounts }) => {
    const navigation = useNavigation();
    const today = moment().toDate();
    const [startDate, setStartDate] = useState(today);
    const { width } = useWindowDimensions();
    const products = [
        {
            "id": "1",
            "name": "External Account",
        },
        {
            "id": "2",
            "name": "External Card",
        },
    ];

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            'amount': '',
            'externalbank_account': selectedExternalAccounts.length == 1 ? selectedExternalAccounts[0]?.nickname : 'Select external account',
            'externalcard': '',
        }
    });

    useEffect(() => {
        setValue('externalbank_account', selectedExternalAccounts.length == 1 ? selectedExternalAccounts[0]?.nickname : 'Select external account')
    }, [selectedExternalAccounts])

    const [selectedProductId, setSelectedProductId] = useState(
        getValues().productId
    );

    const navigateToExternalAccount = () => {
        navigation.push('MoveMoney.externalAccountList', { externalAccounts, accounts, selectedExternalAccounts, screenName: 'MoveMoney.FundMyAccountDetail' })
    }

    const navigateToExternalCard = () => {
        navigation.push('MoveMoney.ExternalCardList', { externalAccounts, accounts, selectedExternalAccounts, screenName: 'MoveMoney.FundMyAccountDetail' })
    }

    const onSubmit = (formData) => {
        const data = {
            toAccount: selectedExternalAccounts ? selectedExternalAccounts[0] : '',
            fromAccount: formData.checking_account
                ? accounts.find((row) => row.id === formData.checking_account)
                : '',
            amount: formData.amount,
            when: startDate,
        };
        // navigation.push('MoveMoney.WithDrawFundReadyTransfer', { data });
    };

    return (
        <View style={{ flex: 1 }}>

            <View style={{ display: 'flex', flexDirection: 'column' }}>
                <Text
                    style={{
                        fontSize: 17,
                        fontFamily: 'DMSans_400Regular',
                        color: 'rgba(0, 0, 0, 0.6)',
                        marginBottom: 5
                    }}
                >
                    {i18n.t('pages.moveMoney.fundMyAccount.fundMyAccountDetails.form.from')}
                </Text>

                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    {products?.map((item) => (
                        <View key={item.id} style={{ flexGrow: 1, flexShrink: 0, flexBasis: '0%', marginRight: 10 }}>
                            <SendMoneyOutAccount
                                key={item.id}
                                product={item}
                                selected={item.id === selectedProductId}
                                onSelect={(productId) => {
                                    if (selectedProductId == productId) {
                                        setSelectedProductId(null);
                                    } else {
                                        setSelectedProductId(productId);
                                    }
                                    setValue('productId', productId);
                                }}
                            />
                        </View>
                    ))}
                </View>

                {selectedProductId == "1" &&
                    <Controller
                        control={control}
                        name="externalbank_account"
                        rules={{
                            required: {
                                value: 'true',
                                message: i18n.t('forms.common.required'),
                            },
                        }}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                errorMessage={
                                    errors.externalbank_account && errors.externalbank_account.message
                                }
                                placeholder="Select external account"
                                onFocus={() => {
                                    navigateToExternalAccount();
                                }}
                                onChangeText={onChange}
                                value={value}
                                style={webViewDesignSupport}
                                rightIcon={{
                                    name: 'keyboard-arrow-right',
                                    onPress: () => navigateToExternalAccount(),
                                }}
                            />
                        )}
                    />}

                {selectedProductId == "2" &&
                    <Controller
                        control={control}
                        name="externalcard"
                        // rules={{
                        //     required: {
                        //         value: 'true',
                        //         message: i18n.t('forms.common.required'),
                        //     },
                        // }}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                errorMessage={
                                    errors.externalcard && errors.externalcard.message
                                }
                                placeholder="Select external card"
                                onFocus={() => navigateToExternalCard()}
                                onChangeText={onChange}
                                value={value}
                                style={webViewDesignSupport}
                                rightIcon={{
                                    name: 'keyboard-arrow-right',
                                    onPress: () => navigateToExternalCard(),
                                }}
                            />
                        )}
                    />}
            </View>

            <Controller
                control={control}
                name="checking_account"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        maxHeight={200}
                        data={accounts?.map((account) => ({
                            id: account.id,
                            name:
                                account.account_type +
                                ' ' +
                                account.account_number,
                        }))}
                        labelField="name"
                        valueField={'id'}
                        label={i18n.t('pages.moveMoney.fundMyAccount.fundMyAccountDetails.form.to')}
                        errorMessage={
                            errors.checking_account && errors.checking_account.message
                        }
                        placeholder="Select account"
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ id }) => onChange(id)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="amount"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(\d+)(\.?)(\d){0,2}$/,
                        message: i18n.t(
                            'pages.moveMoney.fundMyAccount.fundMyAccountDetails.form.invalidAmount'
                        ),
                    },
                }}
                render={({ field: { onChange, value } }) => (
                    <Input
                        errorMessage={errors.amount && errors.amount.message}
                        errorStyle={
                            theme.ReactNativeElementsTheme.Input.errorStyle
                        }
                        label={i18n.t('pages.moveMoney.fundMyAccount.fundMyAccountDetails.form.amount')}
                        textContentType="telephoneNumber"
                        dataDetectorTypes="phoneNumber"
                        keyboardType="phone-pad"
                        placeholder={'$ 0.00'}
                        onChangeText={onChange}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            {isWeb() ?
                <WebDatePicker
                    label={i18n.t('pages.moveMoney.fundMyAccount.fundMyAccountDetails.form.when')}
                    date={new Date(startDate)}
                    onChange={setStartDate}
                /> :
                <DatePickerNative
                    label={i18n.t('pages.moveMoney.fundMyAccount.fundMyAccountDetails.form.when')}
                    date={new Date(startDate)}
                    onChange={setStartDate}
                />
            }

            <View
                style={{
                    bottom: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50
                }}
            >
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('forms.common.continue')}
                    onPress={handleSubmit(onSubmit)}
                />
            </View>
        </View >
    );
};

FundTransferForm.propTypes = {
    externalAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            nickname: PropTypes.string,
            routing_identifiers: PropTypes.shape({
                bank_name: PropTypes.string,
                number: PropTypes.string,
            }),
        })
    ),
    account: PropTypes.shape({
        id: PropTypes.string,
        account_type: PropTypes.string,
        account_number: PropTypes.string,
    }),
    accounts: PropTypes.array,
};
