import { View, TouchableOpacity, Pressable, Modal, StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-elements';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import { isIos } from '../../../common/utils/platform.utils';
import theme from '../../../common/ui/theme';

export const CustomAlert = ({ title, message, modalVisible, setModalVisible }) => {

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(false);
            }}
        >
            <Pressable style={[isIos ? styles.iosBackdrop : styles.androidBackdrop, styles.backdrop]} onPress={() => setModalVisible(false)} />
            <View style={styles.alertBox}>
                <View style={[styles.innerAlertBox]}>
                    <Text style={[styles.modalTitle]}>{title || 'Title'}</Text>
                    <Text style={[styles.modalMessage]}>{message || 'Message'}</Text>
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        title={i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.yesCancelTransfer')}
                        onPress={() => {
                            setModalVisible(false)
                        }}
                    />
                    <TouchableOpacity style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, fontFamily: 'DMSans_500Medium' }} onPress={() => { setModalVisible(false) }}>
                        <Text style={{
                            fontSize: 15,
                            fontFamily: 'DMSans_500Medium',
                            lineHeight: 26,
                            textDecorationLine: 'underline',
                            letterSpacing: 0.46,
                            color: theme.colors.phthaloBlue
                        }}>
                            {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.notNow')}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    iosBackdrop: {
        backgroundColor: "#000000",
        opacity: 0.3
    },
    androidBackdrop: {
        backgroundColor: "#232f34",
        opacity: 0.32
    },
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    alertBox: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 16
    },
    innerAlertBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 24,
        borderRadius: 20,
        backgroundColor: theme.colors.shadWhite,
    },
    modalTitle: {
        fontFamily: 'DMSans_700Bold',
        fontSize: 24,
        lineHeight: 32,
        textAlign: 'center',
        color: theme.colors.phthaloBlue,
        marginBottom: 16
    },
    modalMessage: {
        fontFamily: 'DMSans_400Regular',
        fontSize: 16,
        lineHeight: 19,
        textAlign: 'center',
        marginBottom: 16
    }
});

CustomAlert.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    modalVisible: PropTypes.bool,
    setModalVisible: PropTypes.func
};