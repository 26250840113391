import { Alert, View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../common/ui/components/modal-layout.component';
import React from 'react';
import { api } from '../../../App.context';
import i18n from 'i18n-js';
import { isWeb } from '../../common/utils/platform.utils';
import moment from 'moment';
import theme from '../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { useWindowDimensions } from 'react-native';

export const ReviewDepositModal = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const toast = useToast();

    const {
        params: { data },
    } = useRoute();

    const navigateToHome = () => {
        navigation.navigate('Home');
        toast.show(i18n.t('pages.home.makeDeposit.depositSuccessDescription'), {
            type: 'successWithTitle',
            data: {
                title: i18n.t('pages.home.makeDeposit.depositSuccessTitle'),
            },
        });
    };

    const makeDeposit = (formData) => {
        const amountInCents = formData.amount * 100;

        api.post('/transfer_funds', {
            body: {
                amount: amountInCents,
                transferType: 'debit',
                fromAccount: `${formData.fromAccount.id}`,
                toAccount: `${formData.toAccount.id}`,
                when: formData.when != null ? `${moment(formData.when).format('YYYY-MM-DD')}` : '',
            },
        })
            .then(() => {
                navigateToHome();
            })
            .catch((err) => {
                isWeb()
                    ? toast.show(err.message, {
                          type: 'dangerWithTitle',
                          data: {
                              title: i18n.t('common.errorTitle'),
                          },
                      })
                    : Alert.alert(i18n.t('common.errorTitle'), err.message);
            });
    };

    return (
        <ModalLayout>
            {data && (
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 28,
                                color: 'rgba(0, 0, 0, 0.87)',
                                textTransform: 'capitalize',
                                letterSpacing: 0.15,
                            }}
                        >
                            {i18n.t('pages.home.makeDeposit.form.from')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 28,
                                color: 'rgba(0, 0, 0, 0.87)',
                                textTransform: 'capitalize',
                                letterSpacing: 0.15,
                            }}
                        >
                            {data.fromAccount.nickname}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 28,
                                color: 'rgba(0, 0, 0, 0.87)',
                                textTransform: 'capitalize',
                                letterSpacing: 0.15,
                            }}
                        >
                            {i18n.t('pages.home.makeDeposit.form.to')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 28,
                                color: 'rgba(0, 0, 0, 0.87)',
                                letterSpacing: 0.15,
                            }}
                        >
                            {data.toAccount.account_type}
                        </Text>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 28,
                                color: 'rgba(0, 0, 0, 0.87)',
                                letterSpacing: 0.15,
                            }}
                        >
                            {data.toAccount.account_number}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 28,
                                color: 'rgba(0, 0, 0, 0.87)',
                                letterSpacing: 0.15,
                            }}
                        >
                            {i18n.t('pages.home.makeDeposit.form.amount')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 28,
                                color: 'rgba(0, 0, 0, 0.87)',
                                textTransform: 'capitalize',
                                letterSpacing: 0.15,
                            }}
                        >
                            {'$' + data.amount + '.00'}
                        </Text>
                    </View>
                    {data.when && (
                        <View
                            style={{
                                flexDirection: 'column',
                                marginBottom: 15,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_700Bold',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {i18n.t('pages.home.makeDeposit.form.when')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {moment(data.when).format('MMMM DD, YYYY')}
                            </Text>
                        </View>
                    )}
                    <View
                        style={{
                            bottom: 20,
                            position: isWeb() ? 'fixed' : 'absolute',
                            width: width - 50,
                        }}
                    >
                        <Button
                            buttonStyle={theme.styles.buttonStylePrimary}
                            titleStyle={theme.styles.titleStylePrimary}
                            title={i18n.t('pages.home.makeDeposit.depositNow')}
                            onPress={() => makeDeposit(data)}
                        />
                    </View>
                </View>
            )}
        </ModalLayout>
    );
};
