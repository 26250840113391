import { Alert, View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import React from 'react';
import { api, MainContext } from '../../../../App.context';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import theme from '../../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { useWindowDimensions } from 'react-native';
import { MaskedText } from 'react-native-mask-text';

export const WireReadyTransferModal = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const toast = useToast();

    const {
        params: { beneficiaryData, data },
    } = useRoute();

    const navigateToTransfer = (beneficiaryData, data, beneficiaryProfile) => {
        navigation.push('MoveMoney.WireCompleteTransfer', { beneficiaryData, data })
        toast.show(i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.transferSuccessDescription'), {
            type: 'successWithTitle',
        });
    };

    const moneyTransfer = (beneficiaryData, data, beneficiaryProfile) => {
        const amountInCents = data.amount * 100;
        /*  New API will be needed for the fund transfer */
        navigateToTransfer(beneficiaryData, data);

        /* api.post('/transfer_funds', {
            body: {
                amount: amountInCents,
                transferType: 'debit',
                your_account: `${data.your_account.id}`,
                toAccount: beneficiaryProfile.accountNumber,
            },
        })
            .then((response) => {
                navigateToTransfer(response);
            })
            .catch((err) => {
                isWeb()
                    ? toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    })
                    : Alert.alert(i18n.t('common.errorTitle'), err.message);
            }); */
    };

    return (
        <MainContext.Consumer>
            {({ beneficiaryProfile }) => (
                <ModalLayout>
                    <View style={{ flex: 1 }}>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_700Bold',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.from')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {data?.your_account?.account_type} {' '} 
                                <MaskedText mask="**** **** **** 9999">
                                    {data?.your_account?.account_number}
                                </MaskedText>
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_700Bold',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.to')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {beneficiaryProfile?.beneficiaryName}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {beneficiaryProfile?.accountNumber}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {beneficiaryProfile?.bank_address.street_address_1}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {beneficiaryProfile?.bank_address.city}{','} {beneficiaryProfile?.bank_address.state}{','} {beneficiaryProfile?.bank_address.postal_code}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {beneficiaryProfile?.bank_address.country_code}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_700Bold',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.amount')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {'$' + data?.amount + '.00'}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_700Bold',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.purpose')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 16,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 28,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    letterSpacing: 0.15,
                                }}
                            >
                                {beneficiaryData.state_of_purpose}
                            </Text>
                        </View>
                        <View
                            style={{
                                bottom: 20,
                                position: isWeb() ? 'fixed' : 'absolute',
                                width: width - 50,
                            }}
                        >
                            <Button
                                buttonStyle={theme.styles.buttonStylePrimary}
                                titleStyle={theme.styles.titleStylePrimary}
                                title={i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.transfer')}
                                onPress={() => moneyTransfer(beneficiaryData, data, beneficiaryProfile)}
                            />
                        </View>
                    </View>
                </ModalLayout>
            )}
        </MainContext.Consumer>
    );
};
