import { AuthStateEnum, api, log } from '../../../App.context';
import {
    clearItemAsync,
    getItemAsync,
    setItemAsync,
} from '../utils/secure-store.utils';
import Auth0 from 'react-native-auth0';
import Constants from 'expo-constants';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

const auth0 = new Auth0({
    domain: Constants.manifest.extra.auth0_domain,
    clientId: Constants.manifest.extra.auth0_clientId,
});

export class Auth0Provider {
    constructor({ setAuthState, setAuthUserInfo }) {
        this.setAuthState = setAuthState;
        this.setAuthUserInfo = setAuthUserInfo;
    }

    async refreshSession({ shouldReload }) {
        const currentAuthDetails = await getItemAsync('authDetails');
        const refreshToken = JSON.parse(currentAuthDetails).refreshToken;
        const newAuthDetails = await auth0.auth.refreshToken({ refreshToken });
        await this.setSession({
            authDetails: { ...newAuthDetails, refreshToken },
            shouldReload,
        });
    }

    async setSession({ authDetails, shouldReload }) {
        await setItemAsync(
            'authDetails',
            JSON.stringify({
                accessToken: authDetails.accessToken,
                refreshToken: authDetails.refreshToken,
            })
        );
        log.debug('Auth0 - Access Token', authDetails.accessToken);
        api.jwt(authDetails.accessToken);

        const customerId = jwt_decode(authDetails.accessToken)[
            'https://synctera/customerId'
        ];

        if (shouldReload) {
            this.setAuthState(
                customerId
                    ? AuthStateEnum.Customer_Active
                    : AuthStateEnum.Customer_Pending_Onboarding
            );

            const userInfo = await auth0.auth.userInfo({
                token: authDetails.accessToken,
            });
            log.debug('Auth0 - User Info', userInfo);
            this.setAuthUserInfo(userInfo);
        }
    }

    async resumeSession() {
        const authDetailsString = await getItemAsync('authDetails');

        if (authDetailsString) {
            const authDetails = JSON.parse(authDetailsString);
            const exp = moment.unix(jwt_decode(authDetails.accessToken).exp);
            const now = moment();

            if (now.isAfter(exp)) {
                await this.refreshSession({ shouldReload: true });
            } else {
                await this.setSession({ authDetails, shouldReload: true });
            }
        }
    }

    async signIn({ email, password }) {
        return auth0.auth
            .passwordRealm({
                username: email,
                password,
                realm: Constants.manifest.extra.auth0_realm,
                audience: Constants.manifest.extra.auth0_audience,
                scope: 'openid profile email offline_access',
            })
            .then((authDetails) =>
                this.setSession({ authDetails, shouldReload: true })
            );
    }

    async forgotPassword() {
        // TODO: Not implemented yet.
    }

    async signUp({ email, password }) {
        return auth0.auth
            .createUser({
                connection: Constants.manifest.extra.auth0_realm,
                email: email,
                password: password,
            })
            .then(() => this.signIn({ email, password }));
    }

    async signOut() {
        // Revoke token
        const currentAuthDetails = await getItemAsync('authDetails');
        const refreshToken = JSON.parse(currentAuthDetails).refreshToken;
        await auth0.auth.revoke({ refreshToken }).catch(console.log);

        // Forget saved tokens
        await clearItemAsync('authDetails');

        // Reset states
        api.jwt(null);
        this.setAuthState(AuthStateEnum.Guest);
        this.setAuthUserInfo({});
    }
}
