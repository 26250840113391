import { Alert, View } from 'react-native';
import { AuthStateEnum, MainContext, api } from '../../App.context';
import {
    DrawerContentScrollView,
    DrawerItem,
    DrawerItemList,
    createDrawerNavigator,
} from '@react-navigation/drawer';
import React, { useEffect } from 'react';
import { Icon } from 'react-native-elements';
import { Image } from 'react-native';
import { MoveMoneyStackNavigator } from '../screens/move-money/move-money.navigator';
import { ProfileStackNavigator } from '../screens/profile/profile.navigator';
import PropTypes from 'prop-types';
import { TabNavigator } from './tab.navigator';
import i18n from 'i18n-js';
import { isWeb } from '../common/utils/platform.utils';
import theme from '../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';

const Drawer = createDrawerNavigator();

function CustomDrawerContent(props) {
    return (
        <DrawerContentScrollView {...props}>
            <Image
                source={require('../../assets/images/registrationpage_logo.png')}
                style={{
                    width: 128,
                    height: 34,
                    marginLeft: 16,
                    marginTop: 24,
                    marginBottom: 30,
                }}
            />
            <DrawerItemList {...props} />

            <View
                style={{ height: 1, backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
            />
            <DrawerItem
                label={i18n.t('common.changePassword')}
                labelStyle={{
                    fontFamily: 'DMSans_400Regular',
                    fontWeight: '400',
                    fontSize: 16,
                    color: 'rgba(0, 0, 0, 0.87)',
                    letterSpacing: 0.15,
                    lineHeight: 22,
                }}
                icon={() => (
                    <Icon
                        name="security"
                        //type='material-community'
                        size={24}
                        color="rgba(0, 0, 0, 0.54)"
                    />
                )}
                onPress={() => {}}
            />
            <DrawerItem
                label={i18n.t('common.signout')}
                //inactiveTintColor={theme.colors.textError}
                labelStyle={{
                    fontFamily: 'DMSans_400Regular',
                    fontWeight: '400',
                    fontSize: 16,
                    color: 'rgba(0, 0, 0, 0.87)',
                    letterSpacing: 0.15,
                    lineHeight: 22,
                }}
                icon={() => (
                    <Icon
                        name="logout"
                        type="material"
                        size={24}
                        color="rgba(0, 0, 0, 0.54)"
                    />
                )}
                onPress={() =>
                    isWeb()
                        ? props.signOut()
                        : Alert.alert(
                              i18n.t('common.signout'),
                              i18n.t('common.confirmSignout'),
                              [
                                  {
                                      text: i18n.t('common.cancel'),
                                  },
                                  {
                                      text: i18n.t('common.signout'),
                                      onPress: () => props.signOut(),
                                  },
                              ]
                          )
                }
            />
        </DrawerContentScrollView>
    );
}

CustomDrawerContent.propTypes = {
    signOut: PropTypes.func,
    avatar: PropTypes.string,
};

export const DrawerNavigator = () => {
    const { authState, authUserInfo, signOut, profile, setProfile } =
        React.useContext(MainContext);
    const shouldRender =
        authState === AuthStateEnum.Customer_Active &&
        profile.kyc_status !== 'REJECTED';
    const toast = useToast();

    useEffect(() => {
        if (authState === AuthStateEnum.Customer_Active) {
            api.get('/customer')
                .then((customer) => {
                    setProfile(customer);
                })
                .catch((err) => {
                    isWeb()
                        ? toast.show(err.message, {
                              type: 'dangerWithTitle',
                              data: {
                                  title: i18n.t('common.errorTitle'),
                              },
                          })
                        : Alert.alert('Customer not found', err.message);
                });
        }
    }, [authState]);

    return (
        shouldRender && (
            <Drawer.Navigator
                backBehavior="none"
                drawerContent={(props) => (
                    <CustomDrawerContent
                        {...props}
                        avatar={authUserInfo.picture}
                        signOut={signOut}
                    />
                )}
                screenOptions={{
                    swipeEnabled: false,
                    headerShown: false,
                    drawerType: 'front',
                    drawerStyle: {
                        backgroundColor: 'white',
                    },
                    drawerLabelStyle: {
                        fontFamily: 'DMSans_400Regular',
                        fontWeight: '400',
                        fontSize: 16,
                        //color: 'rgba(0, 0, 0, 0.87)',
                        letterSpacing: 0.15,
                        lineHeight: 22,
                    },
                    drawerActiveBackgroundColor: theme.colors.textBody,
                    drawerActiveTintColor: 'white',
                    drawerInactiveTintColor: theme.colors.textBody,
                }}
            >
                <Drawer.Screen
                    name="Drawer.Home"
                    options={{
                        title: i18n.t('pages.home.tabTitle'),
                        drawerIcon: ({ focused }) => (
                            <Icon
                                name="home"
                                size={24}
                                color={
                                    focused ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)'
                                }
                            />
                        ),
                    }}
                    component={TabNavigator}
                />
                <Drawer.Screen
                    name="Drawer.Profile"
                    options={{
                        title: i18n.t('pages.myProfile.drawerTitle'),
                        drawerIcon: ({ focused }) => (
                            <Icon
                                name="account-circle"
                                type="material-community"
                                size={24}
                                color={
                                    focused ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)'
                                }
                            />
                        ),
                    }}
                    component={ProfileStackNavigator}
                />
                <Drawer.Screen
                    name="Drawer.MoveMoney"
                    options={{
                        title: i18n.t('common.moveMoney'),
                        drawerIcon: ({ focused }) => (
                            <Icon
                                name="swap-horiz"
                                size={24}
                                color={
                                    focused ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)'
                                }
                            />
                        ),
                    }}
                    component={MoveMoneyStackNavigator}
                />
            </Drawer.Navigator>
        )
    );
};
