import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { Button } from 'react-native-elements';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import { ProductTile } from '../../../common/ui/components/product-tile.component';
import { api } from '../../../../App.context';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';

export const OpenAccount = () => {
    const [products, setProducts] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState();
    const [selectedAccountTemplate, setselectedAccountTemplate] = useState();
    const navigation = useNavigation();
    const toast = useToast();

    const fetchProducts = async () => {
        api.get('/products')
            .then((response) => setProducts(response.account_templates))
            .catch((err) => {
                setProducts([]);
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    useEffect(() => fetchProducts(), []);

    const navigateToHome = () => {
        navigation.push('Home');
        toast.show(i18n.t('pages.home.accounts.successBody'), {
            type: 'successWithTitle',
            data: {
                title: i18n.t('pages.home.accounts.successTitle'),
            },
        });
    };

    const openAccount = (selectedAccountTemplate) => {
        if (selectedAccountTemplate === 'LINE_OF_CREDIT') {
            navigation.push('Home.LineOfCreditAccount', { selectedProductId });
        }
        else {
            return api
                .post('/accounts', {
                    body: {
                        account_template_id: selectedProductId,
                    },
                })
                .then(() => {
                    navigateToHome();
                })
                .catch((err) => {
                    toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    });
                });
        }
    };

    return (
        <ModalLayout
            title={i18n.t('pages.home.accounts.title')}
            subtitle={i18n.t('pages.accountSelection.subtitle')}
        >
            {!products && <ActivityIndicator />}
            {products &&
                products.map((p) => (
                    <ProductTile
                        key={p.id}
                        product={p}
                        selected={p.id === selectedProductId}
                        onSelect={(productId) => {
                            setSelectedProductId(productId);
                        }}
                        onSelectedTemplate={(templateProductId) => {
                            setselectedAccountTemplate(templateProductId)
                        }}
                    />
                ))}
            <Button
                buttonStyle={theme.styles.buttonStylePrimary}
                titleStyle={theme.styles.titleStylePrimary}
                disabled={!selectedProductId}
                onPress={() => openAccount(selectedAccountTemplate)}
                title={i18n.t('common.openAccount')}
            />
        </ModalLayout>
    );
};
