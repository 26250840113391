import { Button, Text } from 'react-native-elements';
import { MainContext, api } from '../../../../../App.context';
import { View, useWindowDimensions } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import { isWeb } from '../../../../common/utils/platform.utils';
import { openURL } from 'expo-linking';
import theme from '../../../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';

export const OrderVirtualCard = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const {
        params: { account },
    } = useRoute();
    const toast = useToast();

    const navigateToManageCards = () => {
        navigation.push('Home.ManageCard', { account });
        toast.show(
            i18n.t('pages.home.manageCard.orderVirtualCard.successBody'),
            {
                type: 'successWithTitle',
                data: {
                    title: i18n.t(
                        'pages.home.manageCard.orderVirtualCard.successTitle'
                    ),
                },
            }
        );
    };

    const orderVirtualCard = (profile) => {
        api.post('/card', {
            body: {
                account_id: `${account.id}`,
                form: 'VIRTUAL',
                shipping_address: {
                    address_line_1: `${profile.shipping_address.address_line_1}`,
                    city: `${profile.shipping_address.city}`,
                    country_code: `${profile.shipping_address.country_code}`,
                    postal_code: `${profile.shipping_address.postal_code}`,
                    state: `${profile.shipping_address.state}`,
                },
            },
        })
            .then(() => {
                navigateToManageCards();
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    const callContactNumber = () => {
        openURL(
            i18n.t('pages.home.manageCard.orderVirtualCard.contactNumberHref')
        )
            .then((result) => {
                console.log('callContactNumber: ', result);
            })
            .catch((err) => {
                console.log('callContactNumber: ', err);
            });
    };

    return (
        <MainContext.Consumer>
            {({ profile }) => (
                <ModalLayout
                    title={i18n.t(
                        'pages.home.manageCard.orderVirtualCard.title'
                    )}
                    subtitle={i18n.t(
                        'pages.home.manageCard.orderVirtualCard.item1'
                    )}
                >
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {profile && profile.legal_address.address_line_1}
                        </Text>
                    </View>
                    <View>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {profile && profile.legal_address.city}&#44;
                            {profile && profile.legal_address.state}&#44;
                            {profile && profile.legal_address.postal_code}
                        </Text>
                    </View>
                    <View style={{ marginTop: 18 }}>
                        <Text
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: 12,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 20,
                                letterSpacing: 0.4,
                                color: 'rgba(0, 0, 0, 0.6)',
                            }}
                        >
                            <Text>
                                {i18n.t(
                                    'pages.home.manageCard.orderVirtualCard.item3'
                                )}
                            </Text>
                            <Text>
                                {i18n.t(
                                    'pages.home.manageCard.orderVirtualCard.item4'
                                )}
                            </Text>
                            <Text
                                onPress={() => callContactNumber()}
                                style={{
                                    color: theme.colors.phthaloBlue,
                                }}
                            >
                                {i18n.t(
                                    'pages.home.manageCard.orderVirtualCard.contactNumber'
                                )}
                            </Text>
                        </Text>
                    </View>
                    <View
                        style={{
                            alignItems: 'center',
                            flex: 1,
                        }}
                    >
                        <View
                            style={{
                                bottom: 20,
                                position: isWeb() ? 'fixed' : 'absolute',
                                width: width - 50,
                                marginTop: 30,
                                marginBottom: 15,
                            }}
                        >
                            <Button
                                buttonStyle={theme.styles.buttonStylePrimary}
                                titleStyle={theme.styles.titleStylePrimary}
                                loadingProps={{
                                    color: theme.colors.textHeading,
                                }}
                                title={i18n.t('common.order')}
                                onPress={() => orderVirtualCard(profile)}
                            />
                        </View>
                    </View>
                </ModalLayout>
            )}
        </MainContext.Consumer>
    );
};

OrderVirtualCard.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
        legal_address: PropTypes.shape({
            address_line_1: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            postal_code: PropTypes.string,
        }),
    }),
    onPress: PropTypes.func,
};
