import { ActivityIndicator, SafeAreaView } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-native-elements';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import { ProductTile } from '../../common/ui/components/product-tile.component';
import PropTypes from 'prop-types';
import { api } from '../../../App.context';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { useFormContext } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';

export const AccountSelection = ({ navigation }) => {
    const [products, setProducts] = useState(null);
    const { getValues, setValue } = useFormContext();
    const [selectedProductId, setSelectedProductId] = useState(
        getValues().productId
    );
    const toast = useToast();

    const fetchProducts = async () => {
        api.get('/products')
            .then((response) => setProducts(response.account_templates))
            .catch((err) => {
                setProducts([]);
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    useEffect(() => fetchProducts(), []);

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <OnboardingPageLayout
                step={4}
                of={5}
                title={i18n.t('pages.accountSelection.title')}
                subtitle={i18n.t('pages.accountSelection.subtitle')}
            >
                {!products && <ActivityIndicator />}
                {products &&
                    products.map((p) => (
                        <ProductTile
                            key={p.id}
                            product={p}
                            selected={p.id === selectedProductId}
                            onSelect={(productId) => {
                                setSelectedProductId(productId);
                                setValue('productId', productId);
                            }}
                        />
                    ))}
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    disabled={!selectedProductId}
                    onPress={() => navigation.push('Onboarding.Disclosures')}
                    title={i18n.t('common.continue')}
                    style={webViewDesignSupport}
                />
            </OnboardingPageLayout>
        </SafeAreaView>
    );
};

AccountSelection.propTypes = {
    navigation: PropTypes.shape({
        push: PropTypes.func,
    }),
};
