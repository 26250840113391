import { Button, Input } from 'react-native-elements';
import { Controller, useFormContext } from 'react-hook-form';
import { SafeAreaView, View } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import { MaskedTextInput } from 'react-native-mask-text';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import states from 'states-us';
import provinces from 'provinces-ca';
import theme from '../../common/ui/theme';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';

export const AddressInfo = ({ navigation }) => {
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <OnboardingPageLayout
                step={2}
                of={5}
                title={i18n.t('pages.addressInfo.title')}
                subtitle={i18n.t('pages.addressInfo.subtitle')}
            >
                <AddressForm
                    onContinue={() =>
                        navigation.push('Onboarding.DocumentVerification')
                    }
                />
            </OnboardingPageLayout>
        </SafeAreaView>
    );
};
AddressInfo.propTypes = {
    navigation: PropTypes.shape({
        push: PropTypes.func,
    }),
};

const AddressForm = ({ onContinue }) => {
    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useFormContext();
    const focusInput = true;

    return (
        <View>
            <Controller
                control={control}
                name="legal_address.address_line_1"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            focusInput &&
                            errors.legal_address &&
                            errors.legal_address.address_line_1 &&
                            errors.legal_address.address_line_1.message
                        }
                        label={i18n.t('forms.streetAddress1.label')}
                        placeholder={i18n.t('forms.streetAddress1.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                        autoFocus={true}
                    />
                )}
            />
            <Controller
                control={control}
                name="legal_address.address_line_2"
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            errors.legal_address &&
                            errors.legal_address.address_line_2 &&
                            errors.legal_address.address_line_2.message
                        }
                        label={i18n.t('forms.streetAddress2.label')}
                        placeholder={i18n.t('forms.streetAddress2.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="legal_address.city"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            errors.legal_address &&
                            errors.legal_address.city &&
                            errors.legal_address.city.message
                        }
                        label={i18n.t('forms.city.label')}
                        placeholder={i18n.t('forms.city.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="legal_address.state"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        InputComponent={Dropdown}
                        data={provinces.concat(states.sort())}
                        labelField="name"
                        valueField="abbreviation"
                        errorMessage={
                            errors.legal_address &&
                            errors.legal_address.state &&
                            errors.legal_address.state.message
                        }
                        label={i18n.t('forms.state.label')}
                        placeholder={i18n.t('forms.state.placeholder')}
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ abbreviation }) => onChange(abbreviation)}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="legal_address.postal_code"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^([0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9])$/,
                        message: i18n.t('forms.postalCode.invalidFormat'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            errors.legal_address &&
                            errors.legal_address.postal_code &&
                            errors.legal_address.postal_code.message
                        }
                        keyboardType="phone-pad"
                        label={i18n.t('forms.postalCode.label')}
                        placeholder={i18n.t('forms.postalCode.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                        defaultValue={value}
                    />
                )}
            />

            <Button
                buttonStyle={theme.styles.buttonStylePrimary}
                titleStyle={theme.styles.titleStylePrimary}
                disabled={!isValid}
                title={i18n.t('forms.common.continue')}
                onPress={handleSubmit(onContinue)}
            />
        </View>
    );
};

AddressForm.propTypes = {
    onContinue: PropTypes.func,
    profile: PropTypes.shape({
        dob: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        legal_address: PropTypes.shape({
            address_line_1: PropTypes.string,
            address_line_2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            postal_code: PropTypes.string,
        }),
    }),
};
