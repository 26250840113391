import { Image, TouchableOpacity } from 'react-native';
import React from 'react';
import { Text } from 'react-native-elements';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';

export const ScanConnectProfile = () => {
    const navigation = useNavigation();
    return (
        <TouchableOpacity
            style={{
                justifyContent: 'center',
                flexDirection: 'row',
                marginTop: 30,
                alignItems: 'center',
            }}
            onPress={() => navigation.navigate('Auth.SignIn')}
        >
            <Image
                source={require('../../../../assets/images/maskedIcon.png')}
                style={{ width: 20, height: 16, marginRight: 10 }}
            />
            <Text style={theme.typography.scanProfile}>
                {i18n.t('common.scanProfile')}
            </Text>
        </TouchableOpacity>
    );
};
