import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { Text } from 'react-native-elements';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { ScrollableScreenLayout } from '../../common/ui/components/screen-layout.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { api } from '../../../App.context';

export const MoneyMovementListScreen = () => {
    const navigation = useNavigation();
    const [accounts, setAccounts] = useState([]);
    const selectedExternalAccounts = [];

    const fetchAccounts = () => {
        setAccounts(null);
        api.get('/accounts')
            .then((response) => setAccounts(response.accounts))
            .catch((err) => {
                setAccounts([]);
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    useFocusEffect(
        useCallback(() => {
            fetchAccounts();
        }, [])
    );

    return (
        <ScrollableScreenLayout>
            <View>
                <ActionItem
                    title={i18n.t(
                        'pages.moveMoney.withdrawFund.title'
                    )}
                    description={i18n.t(
                        'pages.moveMoney.withdrawFund.description'
                    )}
                    iconName={require('../../../assets/images/outbound.png')}
                    onClick={() =>
                        navigation.push('MoveMoney.WithDrawFundDetail', { accounts, selectedExternalAccounts })
                    }
                >
                </ActionItem>
                <ActionItem
                    title={i18n.t(
                        'pages.moveMoney.fundMyAccount.title'
                    )}
                    description={i18n.t(
                        'pages.moveMoney.fundMyAccount.description'
                    )}
                    iconName={require('../../../assets/images/input.png')}
                    onClick={() =>
                        navigation.push('MoveMoney.FundMyAccountDetail', { accounts, selectedExternalAccounts })
                    }
                />
                <ActionItem
                    title={i18n.t(
                        'pages.moveMoney.transferAccounts.title'
                    )}
                    description={i18n.t(
                        'pages.moveMoney.transferAccounts.description'
                    )}
                    iconName={require('../../../assets/images/swapHorizontalCircle.png')}
                    onClick={() =>
                        navigation.push('MoveMoney.AccountDetails', { accounts })
                    }
                />
                <ActionItem
                    title={i18n.t(
                        'pages.moveMoney.wireTransfer.title'
                    )}
                    description={i18n.t(
                        'pages.moveMoney.wireTransfer.description'
                    )}
                    iconName={require('../../../assets/images/language.png')}
                    onClick={() =>
                        navigation.push('MoveMoney.WireTransferDetail', { accounts })
                    }
                />
                <ActionItem
                    title={i18n.t(
                        'pages.moveMoney.manageTransfer.title'
                    )}
                    description={i18n.t(
                        'pages.moveMoney.manageTransfer.description'
                    )}
                    iconName={require('../../../assets/images/calenderEvent.png')}
                    onClick={() =>
                        navigation.push('MoveMoney.ManageTransferList')
                    }
                />
            </View>
        </ScrollableScreenLayout>
    );
};

const ActionItem = ({
    title,
    description,
    iconName,
    children,
    onClick,
}) => {
    return (
        <View style={theme.styles.lightBlueBorder}>
            <TouchableOpacity
                onPress={() => onClick()}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <Image
                                source={iconName}
                                style={{ width: 22, height: 22 }}
                            />
                            <Text
                                style={{
                                    fontSize: 17,
                                    marginLeft: 10,
                                    fontWeight: '700',
                                    lineHeight: 20,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    ...theme.typography.boldFontWeight,
                                }}
                            >
                                {title}
                            </Text>
                        </View>
                        <View style={{ flex: 0.2 }}>{children}</View>
                    </View>
                    <Text
                        style={{
                            marginTop: 10,
                            fontSize: 12,
                            fontWeight: '400',
                            lineHeight: 20,
                            letterSpacing: 0.4,
                            color: 'rgba(0, 0, 0, 0.87)',
                            ...theme.typography.regularFontWeight,
                        }}
                    >
                        {description}
                    </Text>
                </View>
            </TouchableOpacity>
        </View>
    );
};

ActionItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.any,
    iconName: PropTypes.any,
};

