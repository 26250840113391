import { Button, Input } from 'react-native-elements';
import { Controller, useFormContext } from 'react-hook-form';
import { SafeAreaView, View } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import MaskInput from 'react-native-mask-input';
import { MaskedTextInput } from 'react-native-mask-text';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import { getData } from 'country-list';
import i18n from 'i18n-js';
import { isWeb } from '../../common/utils/platform.utils';
import theme from '../../common/ui/theme';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';

const countries = getData();
countries.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

export const PersonalInfo = ({ navigation }) => {
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <OnboardingPageLayout
                step={1}
                of={5}
                title={i18n.t('pages.personalInfo.title')}
                subtitle={i18n.t('pages.personalInfo.subtitle')}
                screenName="personalInfo"
                screenNavigation={navigation}
            >
                <PersonalInfoForm
                    onContinue={() => navigation.push('Onboarding.AddressInfo')}
                />
            </OnboardingPageLayout>
        </SafeAreaView>
    );
};

PersonalInfo.propTypes = {
    navigation: PropTypes.shape({
        push: PropTypes.func,
    }),
};

const PersonalInfoForm = ({ onContinue }) => {
    const {
        control,
        formState: { isValid, errors },
    } = useFormContext();

    const ssnMask = [
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        '-',
        [/[0-9]/],
        [/[0-9]/],
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
    ];

    return (
        <View style={{ flex: 1 }}>
            <Controller
                control={control}
                name="first_name"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            errors.first_name &&
                            errors.first_name.message
                        }
                        label={i18n.t('forms.firstName.label')}
                        placeholder={i18n.t('forms.firstName.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                        autoFocus={true}
                    />
                )}
            />

            <Controller
                control={control}
                name="last_name"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            errors.last_name && errors.last_name.message
                        }
                        label={i18n.t('forms.lastName.label')}
                        placeholder={i18n.t('forms.lastName.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="legal_address.country_code"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        InputComponent={Dropdown}
                        data={countries.sort()}
                        labelField="name"
                        valueField="code"
                        errorMessage={
                            errors.legal_address &&
                            errors.legal_address.country_code &&
                            errors.legal_address.country_code.message
                        }
                        label={i18n.t('forms.citizenship.label')}
                        placeholder={i18n.t('forms.citizenship.placeholder')}
                        onChange={({ code }) => onChange(code)}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Controller
                    control={control}
                    name="dob"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                        pattern: {
                            value: /^(0?[1-9]|1[0-2])\/[0-9]{2}\/[0-9]{4}$/,
                            message: i18n.t('forms.dob.invalidFormat'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            containerStyle={{ flex: 1, marginRight: 15 }}
                            InputComponent={MaskedTextInput}
                            mask="99/99/9999"
                            keyboardType="numeric"
                            errorMessage={errors.dob && errors.dob.message}
                            label={i18n.t('forms.dob.label')}
                            placeholder={i18n.t('forms.dob.placeholder')}
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={
                                isWeb()
                                    ? { maxWidth: '100%', outlineStyle: 'none' }
                                    : null
                            }
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="ssn"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                        pattern: {
                            value: /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
                            message: i18n.t('forms.ssn.invalidFormat'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            containerStyle={{ flex: 1, marginLeft: 15 }}
                            InputComponent={MaskInput}
                            mask={ssnMask}
                            showObfuscatedValue
                            obfuscationCharacter="*"
                            errorMessage={errors.ssn && errors.ssn.message}
                            keyboardType="numeric"
                            label={i18n.t('forms.ssn.label')}
                            placeholder={i18n.t('forms.ssn.placeholder')}
                            onChangeText={(masked) => {
                                onChange(masked);
                            }}
                            onBlur={onBlur}
                            value={value}
                            style={
                                isWeb()
                                    ? { maxWidth: '100%', outlineStyle: 'none' }
                                    : null
                            }
                        />
                    )}
                />
            </View>
            <Controller
                control={control}
                name="phone_number"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^\+1\s[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                        message: i18n.t('forms.phoneNumber.invalidFormat'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        InputComponent={MaskedTextInput}
                        mask="+1 999-999-9999"
                        errorMessage={
                            errors.phone_number && errors.phone_number.message
                        }
                        keyboardType="phone-pad"
                        label={i18n.t('forms.phoneNumber.label')}
                        placeholder={i18n.t('forms.phoneNumber.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Button
                buttonStyle={theme.styles.buttonStylePrimary}
                titleStyle={theme.styles.titleStylePrimary}
                disabled={!isValid}
                title={i18n.t('forms.common.continue')}
                onPress={onContinue}
            />
        </View>
    );
};

PersonalInfoForm.propTypes = {
    onContinue: PropTypes.func,
};
