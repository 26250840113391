import { Button, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'react-native-element-dropdown';
import { MainContext, api } from '../../../../App.context';
import { View, useWindowDimensions } from 'react-native';
import React from 'react';
import { ScrollableScreenLayout } from '../../../common/ui/components/screen-layout.component';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import theme from '../../../common/ui/theme';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { webViewDesignSupport } from '../../../common/utils/secure-store.utils';
import { getData } from 'country-list';

const countries = getData();
countries.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

export const EditBeneficiaryInfo = () => {
    const { width, height } = useWindowDimensions();
    const navigation = useNavigation();

    const {
        params: { beneficiaryProfile, data },
    } = useRoute();

    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        defaultValues:
        {
            beneficiaryName: beneficiaryProfile.beneficiaryName,
            accountNumber: beneficiaryProfile.accountNumber,
            bank_name: beneficiaryProfile.bank_name,
            bank_address: {
                street_address_1: beneficiaryProfile.bank_address.street_address_1,
                // street_address_2: beneficiaryProfile.bank_address.street_address_2,
                city: beneficiaryProfile.bank_address.city,
                country_code: beneficiaryProfile.bank_address.country_code,
                postal_code: beneficiaryProfile.bank_address.postal_code,
                state: beneficiaryProfile.bank_address.state,
            }
        }
    });

    const toast = useToast();

    const { setBeneficiaryProfile } = React.useContext(MainContext);

    const onSubmit = (formData) => {
        console.log('formData', formData);
        const body = {
            beneficiaryName: formData.beneficiaryName,
            accountNumber: formData.accountNumber,
            bank_name: formData.bank_name,
            bank_address: {
                street_address_1: formData.bank_address.street_address_1,
                // street_address_2: formData.bank_address.street_address_2,
                city: formData.bank_address.city,
                country_code: formData.bank_address.country_code,
                // postal_code: formData.bank_address.postal_code,
                // state: formData.bank_address.state,
            }
        };
        setBeneficiaryProfile(body);
        console.log('data', data)
        navigation.navigate('MoveMoney.beneficiaryInformationDetail', { data });

        /* New API will be integrated */
        /* return api
            .patch('/customer', { body })
            .then((response) => {
                setBeneficiaryProfile(response);
                toast.show(i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.toast.success'), {
                    type: 'successWithTitle',
                });
                navigation.navigate('MoveMoney.beneficiaryInformationDetail');
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            }); */
    };

    return (
        <ScrollableScreenLayout>
            <View style={{ flex: 1 }}>
                <Controller
                    control={control}
                    name="beneficiaryName"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.beneficiaryName &&
                                errors.beneficiaryName.message
                            }
                            label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.beneficiaryName')}
                            placeholder={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.beneficiaryName')}
                            autoCapitalize="none"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="accountNumber"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.accountNumber &&
                                errors.accountNumber.message
                            }
                            label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.accountName')}
                            placeholder={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.accountName')}
                            autoCapitalize="none"
                            keyboardType="numeric"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="bank_name"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.bank_name &&
                                errors.bank_name.message
                            }
                            label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.bankDetails.bankName')}
                            placeholder={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.bankDetails.placeholder')}
                            autoCapitalize="none"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            rightIcon={{
                                name: 'search',
                            }}
                            autoFocus={true}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="bank_address.street_address_1"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.bank_address &&
                                errors.bank_address.street_address_1 &&
                                errors.bank_address.street_address_1.message
                            }
                            label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.bankAddress')}
                            autoCapitalize="none"
                            placeholder={i18n.t('forms.streetAddress1.placeholder')}
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="bank_address.city"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.bank_address &&
                                errors.bank_address.city &&
                                errors.bank_address.city.message
                            }
                            label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.bankCity')}
                            placeholder={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.bankCity')}
                            autoCapitalize="none"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="bank_address.country_code"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            InputComponent={Dropdown}
                            data={countries.sort()}
                            labelField="name"
                            valueField="code"
                            errorMessage={
                                errors.bank_address &&
                                errors.bank_address.country_code &&
                                errors.legal_bankaddress.country_code.message
                            }
                            label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.countryDetails.label')}
                            placeholder={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.countryDetails.placeholder')}
                            onChange={({ code }) => onChange(code)}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                        />
                    )}
                />
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    // disabled={!isValid}
                    title={i18n.t('forms.common.save')}
                    onPress={handleSubmit(onSubmit)}
                />
            </View>
        </ScrollableScreenLayout>
    );
};
