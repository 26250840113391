import { SafeAreaView } from 'react-native';
import React, { useState } from 'react';
import { Button } from 'react-native-elements';
import { Helmet } from 'react-helmet';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import { VerifyAccount } from '../../common/ui/components/verify-account.component';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { useFormContext } from 'react-hook-form';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import Socure from 'rn-socure-sdk';
import { isWeb } from '../../common/utils/platform.utils';

const startLicense = (navigation) => {
    console.log("startLicense called")
    // Socure.scanLicense().then(() => {
    navigation.push('Onboarding.AccountSelection')
    // });
};


const startPassport = (navigation) => {
    console.log("startPassport called")
    // Socure.scanPassport().then(() => {
    navigation.push('Onboarding.AccountSelection')
    // });
};

export const DocumentVerification = ({ navigation }) => {
    const products = [
        {
            "id": "1",
            "name": "Driver's license",
        },
        {
            "id": "2",
            "name": "Passport",
        },
    ];
    const { getValues, setValue } = useFormContext();
    const [selectedProductId, setSelectedProductId] = useState(
        getValues().productId
    );

    var config = {
        onProgress: () => { }, //callback method for reading the progress status
        onSuccess: (metadata, navigation) => { //callback method to read the success response
            console.log('response onSucesss', metadata)
            navigation.push('Onboarding.AccountSelection');
        },
        onError: (errorResponse) => { //callback method to read the error response
            console.log('errorResponse', errorResponse)
        },
        qrCodeNeeded: true
    };

    const start = (navigation) => {
        SocureInitializer.init("f0707f6d-0f2e-4fa6-89b8-44a10ae20c21")
            .then(lib => {
                lib.init("f0707f6d-0f2e-4fa6-89b8-44a10ae20c21", "body", config).then(function () {
                    lib.start(2, { language: "es" }).then(
                        function (metadata) {
                            config.onSuccess(metadata, navigation)
                        },
                        function (errorResponse) {
                            config.onError(errorResponse)
                        }
                    );
                });
            });
    }

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <OnboardingPageLayout
                step={3}
                of={5}
                title={i18n.t('pages.documentVerify.title')}
                subtitle={i18n.t('pages.documentVerify.subtitle')}
                screenName="verifyAccount"
                screenNavigation={navigation}
            >
                {isWeb() ?
                    <Helmet>
                        <script type="text/javascript" src="https://websdk.socure.com/bundle.js"></script>
                    </Helmet> : null
                }
                {products?.map((item) => (
                    <VerifyAccount
                        key={item.id}
                        product={item}
                        selected={item.id === selectedProductId}
                        onSelect={(productId) => {
                            if (selectedProductId == productId) {
                                setSelectedProductId(null);
                            } else {
                                setSelectedProductId(productId);
                            }
                            setValue('productId', productId);
                        }}
                    />
                ))}
                {isWeb() ?
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        disabled={!selectedProductId}
                        onPress={() => start(navigation)}
                        title={i18n.t('common.continue')}
                        style={webViewDesignSupport}
                    /> :
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        disabled={!selectedProductId}
                        onPress={() => selectedProductId == "1" ? startLicense(navigation) : startPassport(navigation)}
                        title={i18n.t('common.continue')}
                    />}
            </OnboardingPageLayout>
        </SafeAreaView>
    );
};

DocumentVerification.propTypes = {
    navigation: PropTypes.shape({
        push: PropTypes.func,
    }),
};
