import { Alert, Image, View } from 'react-native';
import { Button } from 'react-native-elements';
import React, { useEffect, useState } from 'react';
import { api, log } from '../../../../App.context';
import { isAndroid, isWeb } from '../../../common/utils/platform.utils';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import { PlaidLinkNative } from '../../accounts/components/plaid-link-native.component';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { usePlaidLink } from 'react-plaid-link';
import { useToast } from 'react-native-toast-notifications';
import { useWindowDimensions } from 'react-native';

export const LinkExternalAccount = () => {
    const toast = useToast();

    const {
        params: { externalAccounts },
    } = useRoute();
    const [isexternalAccounts, setExternalAccounts] = useState(externalAccounts);

    const fetchExternalAccountsAccounts = () => {
        api.get('/external_accounts')
            .then((r) => setExternalAccounts(r.external_accounts))
            .catch((err) => {
                isWeb()
                    ? toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    })
                    : Alert.alert(i18n.t('common.errorTitle'), err.message);
            });
    };

    useEffect(() => fetchExternalAccountsAccounts(), []);

    return (
        <ModalLayout
            subtitle={i18n.t('pages.moveMoney.withdrawFund.exteranalAccountList.addExternalAccount.subtitle')}
        >
            <AddExternalAccount
                onLinkingExternalAccount={() => setExternalAccounts(isexternalAccounts)}
                onExternalAccountLinked={(addedExternalAccounts) =>
                    fetchExternalAccountsAccounts(addedExternalAccounts)
                }
            />
        </ModalLayout>
    );
};

const AddExternalAccount = ({
    onLinkingExternalAccount,
    onExternalAccountLinked,
}) => {
    const { width } = useWindowDimensions();
    const [plaidReady, setPlaidReady] = useState(false);
    const [linkToken, setLinkToken] = useState();
    const toast = useToast();
    const [customerId, setCustomerId] = useState();

    /* Plaid integration */
    /**
     * Function to retrieve link token. Required to open the Plaid Link UI
     */
    const fetchLinkToken = () => {
        api.get('/external_accounts/link_token')
            .then((response) => {
                setCustomerId(response.customer_id);
                setLinkToken(response.link_token);
                setPlaidReady(!plaidReady);
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    /**
     * Function to get a Plaid access token. Required to add an external account.
     */
    const fetchAccessToken = ({ publicToken, institutionId }) => {
        return api
            .post('/external_accounts/access_token', {
                body: {
                    vendor_customer_id: customerId, // Synctera docs: The customerId value of the account associated with the returned link_token
                    vendor_institution_id: institutionId,
                    vendor_public_token: publicToken,
                },
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    /**
     * Function add add/store external accounts
     */
    const addExternalAccounts = ({ accessToken, accountIds }) => {
        return api
            .post('/external_accounts/add_vendor_accounts', {
                body: {
                    vendor_access_token: accessToken,
                    vendor_account_ids: accountIds,
                },
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    const saveExternalAccount = async (linkSuccess, { metadata }) => {
        onLinkingExternalAccount && onLinkingExternalAccount();

        const institutionId =
            metadata.institution && metadata.institution.institution_id;
        const accountIds = metadata?.accounts?.map((a) => a.id);
        const publicToken = linkSuccess;

        try {
            // Fetch Access Token
            const accessToken = await fetchAccessToken({
                publicToken,
                institutionId,
            });
            log.debug('Plaid - Access Token - called', accessToken);
            // Add External Account
            const externalAccounts = await addExternalAccounts({
                accessToken: accessToken.vendor_access_token,
                accountIds,
            });

            onExternalAccountLinked &&
                onExternalAccountLinked(externalAccounts && externalAccounts.added_accounts);

            log.debug('Plaid - External account linked', externalAccounts);
        } catch (e) {
            log.error('Plaid - Error adding external account');
            isWeb()
                ? toast.show(e.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                })
                : Alert.alert(i18n.t('pages.accounts.plaid.linkingError'));
        }
    };

    useEffect(() => fetchLinkToken(), []);

    const config = {
        onSuccess: (linkSuccess, metadata) => {
            saveExternalAccount(linkSuccess, { metadata });
        },
        token: linkToken,
    };

    const { open, ready } = usePlaidLink(config);

    return (
        <View style={{ alignItems: 'center', flex: 1 }}>
            <View
                style={
                    isAndroid()
                        ? { flex: 0.7, justifyContent: 'center' }
                        : { flex: 1, justifyContent: 'center' }
                }
            >
                <Image
                    resizeMode="contain"
                    source={require('../../../../assets/images/mask_group_deposit.png')}
                    style={{ width: 293, height: 307 }}
                />
            </View>
            <View
                style={{
                    bottom: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50,
                }}
            >
                <PlaidLinkNative
                    accountId={customerId}
                    onReady={(ready) => setPlaidReady(ready)}
                    onLinking={() => onLinkingExternalAccount()}
                    onSuccess={(externalAccounts) =>
                        onExternalAccountLinked(externalAccounts)
                    }
                    fetchAccessToken={fetchAccessToken}
                    addExternalAccounts={addExternalAccounts}
                    linkToken={linkToken}
                >
                    {isWeb() ? (
                        <Button
                            buttonStyle={theme.styles.buttonStylePrimary}
                            titleStyle={theme.styles.titleStylePrimary}
                            onPress={() => {
                                open();
                            }}
                            loading={!plaidReady}
                            loadingProps={{ color: theme.colors.textHeading }}
                            title={i18n.t(
                                'pages.moveMoney.withdrawFund.exteranalAccountList.addExternalAccount.confirm'
                            )}
                            disabled={!ready}
                            disabledStyle={
                                theme.ReactNativeElementsTheme.Button
                                    .buttonStyle
                            }
                            disabledTitleStyle={
                                theme.ReactNativeElementsTheme.Button.titleStyle
                            }
                        />
                    ) : (
                        <Button
                            buttonStyle={theme.styles.buttonStylePrimary}
                            titleStyle={theme.styles.titleStylePrimary}
                            loading={!plaidReady}
                            loadingProps={{ color: theme.colors.textHeading }}
                            title={i18n.t(
                                'pages.moveMoney.withdrawFund.exteranalAccountList.addExternalAccount.confirm'
                            )}
                            disabled={!plaidReady}
                            disabledStyle={
                                theme.ReactNativeElementsTheme.Button
                                    .buttonStyle
                            }
                            disabledTitleStyle={
                                theme.ReactNativeElementsTheme.Button.titleStyle
                            }
                        />
                    )}
                </PlaidLinkNative>
            </View>
        </View>
    );
};

AddExternalAccount.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
    }),
    onLinkingExternalAccount: PropTypes.func.isRequired,
    onExternalAccountLinked: PropTypes.func.isRequired,
};
