import { ActivityIndicator, Alert, Image, View } from 'react-native';
import { Button, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { api, log } from '../../../App.context';
import { isAndroid, isWeb } from '../../common/utils/platform.utils';
import { useNavigation, useRoute } from '@react-navigation/native';
import { DatePickerNative } from './components/date-picker.component';
import { Dropdown } from 'react-native-element-dropdown';
import { ModalLayout } from '../../common/ui/components/modal-layout.component';
import { PlaidLinkNative } from '../accounts/components/plaid-link-native.component';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { isEmpty } from 'lodash';
import moment from 'moment';
import theme from '../../common/ui/theme';
import { usePlaidLink } from 'react-plaid-link';
import { useToast } from 'react-native-toast-notifications';
import { useWindowDimensions } from 'react-native';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { WebDatePicker } from './components/web-date-picker.component';

export const MakeDepositModal = () => {
    const [externalAccounts, setExternalAccounts] = useState(null);
    const hasAccounts = !isEmpty(externalAccounts);
    const accountsFetched = externalAccounts !== null;
    const toast = useToast();

    const {
        params: { accounts, account },
    } = useRoute();

    const fetchExternalAccountsAccounts = () => {
        api.get('/external_accounts')
            .then((r) => setExternalAccounts(r.external_accounts))
            .catch((err) => {
                isWeb()
                    ? toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    })
                    : Alert.alert(i18n.t('common.errorTitle'), err.message);
            });
    };

    useEffect(() => fetchExternalAccountsAccounts(), []);

    return (
        <ModalLayout
            subtitle={
                !accountsFetched || hasAccounts
                    ? null
                    : i18n.t('pages.home.addExternalAccount.subtitle')
            }
        >
            {!accountsFetched && <ActivityIndicator />}
            {accountsFetched && !hasAccounts && account && (
                <AddExternalAccount
                    account={account}
                    onLinkingExternalAccount={() => setExternalAccounts(null)}
                    onExternalAccountLinked={(addedExternalAccounts) =>
                        fetchExternalAccountsAccounts(addedExternalAccounts)
                    }
                />
            )}
            {hasAccounts && accounts && (
                <MakeDeposit
                    accounts={accounts}
                    externalAccounts={externalAccounts}
                />
            )}
        </ModalLayout>
    );
};

const MakeDeposit = ({ externalAccounts, accounts }) => {
    return (
        <View style={{ flex: isWeb() ? 1 : 0.85 }}>
            <MakeDepositForm
                externalAccounts={externalAccounts}
                accounts={accounts}
            />
        </View>
    );
};

MakeDeposit.propTypes = {
    externalAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            nickname: PropTypes.string,
            routing_identifiers: PropTypes.shape({
                bank_name: PropTypes.string,
                number: PropTypes.string,
            }),
        })
    ),
    review: PropTypes.bool,
    setReview: PropTypes.func,
    accounts: PropTypes.array,
};

const MakeDepositForm = ({ externalAccounts, accounts }) => {
    const { width } = useWindowDimensions();

    const navigation = useNavigation();
    const today = moment().toDate();
    const [startDate, setStartDate] = useState(today);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            'amount': ''
        }
    });

    const onSubmit = (formData) => {
        const data = {
            fromAccount: formData.from_account
                ? externalAccounts.find(
                    (row) => row.id === formData.from_account
                )
                : '',
            toAccount: formData.to_account
                ? accounts.find((row) => row.id === formData.to_account)
                : '',
            amount: formData.amount,
            when: startDate,
        };
        navigation.push('Home.ReviewDeposit', { data });
    };

    return (
        <View style={{ flex: 1 }}>
            <Controller
                control={control}
                name="from_account"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        data={externalAccounts.map((row) => row)}
                        maxHeight={200}
                        labelField={'nickname'}
                        valueField="id"
                        label={i18n.t('pages.home.makeDeposit.form.from')}
                        errorMessage={
                            errors.from_account && errors.from_account.message
                        }
                        placeholder="Select account"
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ id }) => onChange(id)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="to_account"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        maxHeight={200}
                        data={accounts.map((account) => ({
                            id: account.id,
                            name:
                                account.account_type +
                                ' ' +
                                account.account_number,
                        }))}
                        labelField="name"
                        valueField={'id'}
                        label={i18n.t('pages.home.makeDeposit.form.to')}
                        errorMessage={
                            errors.to_account && errors.to_account.message
                        }
                        placeholder="Select account"
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ id }) => onChange(id)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="amount"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(\d+)(\.?)(\d){0,2}$/,
                        message: i18n.t(
                            'pages.home.makeDeposit.form.invalidAmount'
                        ),
                    },
                }}
                render={({ field: { onChange, value } }) => (
                    <Input
                        errorMessage={errors.amount && errors.amount.message}
                        errorStyle={
                            theme.ReactNativeElementsTheme.Input.errorStyle
                        }
                        label={i18n.t('pages.home.makeDeposit.form.amount')}
                        textContentType="telephoneNumber"
                        dataDetectorTypes="phoneNumber"
                        keyboardType="phone-pad"
                        placeholder={'$ 0.00'}
                        onChangeText={onChange}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            {isWeb() ?
                <WebDatePicker
                    label={i18n.t('pages.home.makeDeposit.form.when')}
                    date={new Date(startDate)}
                    onChange={setStartDate}
                /> :
                <DatePickerNative
                    label={i18n.t('pages.home.makeDeposit.form.when')}
                    date={new Date(startDate)}
                    onChange={setStartDate}
                />
            }

            <View
                style={{
                    bottom: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50,
                }}
            >
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('forms.common.continue')}
                    onPress={handleSubmit(onSubmit)}
                />
            </View>
        </View>
    );
};

MakeDepositForm.propTypes = {
    externalAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            nickname: PropTypes.string,
            routing_identifiers: PropTypes.shape({
                bank_name: PropTypes.string,
                number: PropTypes.string,
            }),
        })
    ),
    account: PropTypes.shape({
        id: PropTypes.string,
        account_type: PropTypes.string,
        account_number: PropTypes.string,
    }),
    accounts: PropTypes.array,
};

const AddExternalAccount = ({
    onLinkingExternalAccount,
    onExternalAccountLinked,
}) => {
    const { width } = useWindowDimensions();
    const [plaidReady, setPlaidReady] = useState(false);
    const [linkToken, setLinkToken] = useState();
    const toast = useToast();
    const [customerId, setCustomerId] = useState();

    /* Plaid integration */
    /**
     * Function to retrieve link token. Required to open the Plaid Link UI
     */
    const fetchLinkToken = () => {
        api.get('/external_accounts/link_token')
            .then((response) => {
                setCustomerId(response.customer_id);
                setLinkToken(response.link_token);
                setPlaidReady(!plaidReady);
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    /**
     * Function to get a Plaid access token. Required to add an external account.
     */
    const fetchAccessToken = ({ publicToken, institutionId }) => {
        return api
            .post('/external_accounts/access_token', {
                body: {
                    vendor_customer_id: customerId, // Synctera docs: The customerId value of the account associated with the returned link_token
                    vendor_institution_id: institutionId,
                    vendor_public_token: publicToken,
                },
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    /**
     * Function add add/store external accounts
     */
    const addExternalAccounts = ({ accessToken, accountIds }) => {
        console.log('plaitest in external account: ');

        return api
            .post('/external_accounts/add_vendor_accounts', {
                body: {
                    vendor_access_token: accessToken,
                    vendor_account_ids: accountIds,
                },
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    const saveExternalAccount = async (linkSuccess, { metadata }) => {
        onLinkingExternalAccount && onLinkingExternalAccount();

        const institutionId =
            metadata.institution && metadata.institution.institution_id;
        const accountIds = metadata?.accounts?.map((a) => a.id);
        const publicToken = linkSuccess;

        try {
            // Fetch Access Token
            const accessToken = await fetchAccessToken({
                publicToken,
                institutionId,
            });
            log.debug('Plaid - Access Token', accessToken);
            // Add External Account
            const externalAccounts = await addExternalAccounts({
                accessToken: accessToken.vendor_access_token,
                accountIds,
            });

            onExternalAccountLinked &&
                onExternalAccountLinked(externalAccounts);

            log.debug('Plaid - External account linked', externalAccounts);
        } catch (e) {
            log.error('Plaid - Error adding external account');
            isWeb()
                ? toast.show(e.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                })
                : Alert.alert(i18n.t('pages.accounts.plaid.linkingError'));
        }
    };

    useEffect(() => fetchLinkToken(), []);

    const config = {
        onSuccess: (linkSuccess, metadata) => {
            saveExternalAccount(linkSuccess, { metadata });
        },
        token: linkToken,
    };

    const { open, ready } = usePlaidLink(config);

    return (
        <View style={{ alignItems: 'center', flex: 1 }}>
            <View
                style={
                    isAndroid()
                        ? { flex: 0.7, justifyContent: 'center' }
                        : { flex: 1, justifyContent: 'center' }
                }
            >
                <Image
                    resizeMode="contain"
                    source={require('../../../assets/images/mask_group_deposit.png')}
                    style={{ width: 293, height: 307 }}
                />
            </View>
            <View
                style={{
                    bottom: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50,
                }}
            >
                <PlaidLinkNative
                    accountId={customerId}
                    onReady={(ready) => setPlaidReady(ready)}
                    onLinking={() => onLinkingExternalAccount()}
                    onSuccess={(externalAccounts) =>
                        onExternalAccountLinked(externalAccounts)
                    }
                    fetchAccessToken={fetchAccessToken}
                    addExternalAccounts={addExternalAccounts}
                    linkToken={linkToken}
                >
                    {isWeb() ? (
                        <Button
                            buttonStyle={theme.styles.buttonStylePrimary}
                            titleStyle={theme.styles.titleStylePrimary}
                            onPress={() => {
                                open();
                            }}
                            loading={!plaidReady}
                            loadingProps={{ color: theme.colors.textHeading }}
                            title={i18n.t(
                                'pages.home.addExternalAccount.confirm'
                            )}
                            disabled={!ready}
                            disabledStyle={
                                theme.ReactNativeElementsTheme.Button
                                    .buttonStyle
                            }
                            disabledTitleStyle={
                                theme.ReactNativeElementsTheme.Button.titleStyle
                            }
                        />
                    ) : (
                        <Button
                            buttonStyle={theme.styles.buttonStylePrimary}
                            titleStyle={theme.styles.titleStylePrimary}
                            loading={!plaidReady}
                            loadingProps={{ color: theme.colors.textHeading }}
                            title={i18n.t(
                                'pages.home.addExternalAccount.confirm'
                            )}
                            disabled={!plaidReady}
                            disabledStyle={
                                theme.ReactNativeElementsTheme.Button
                                    .buttonStyle
                            }
                            disabledTitleStyle={
                                theme.ReactNativeElementsTheme.Button.titleStyle
                            }
                        />
                    )}
                </PlaidLinkNative>
            </View>
        </View>
    );
};

AddExternalAccount.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
    }),
    onLinkingExternalAccount: PropTypes.func.isRequired,
    onExternalAccountLinked: PropTypes.func.isRequired,
};
