import { Button, Text } from 'react-native-elements';
import { View, useWindowDimensions } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { MainContext } from '../../../../../App.context';
import { ModalLayout } from '../../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import { isWeb } from '../../../../common/utils/platform.utils';
import moment from 'moment';
import theme from '../../../../common/ui/theme';

export const PhysicalCardOrdered = () => {
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const {
        params: { account, response },
    } = useRoute();

    const dateToday = moment().format('LL');
    const dateAfterTenDays = moment().add(10, 'day').format('LL');

    return (
        <MainContext.Consumer>
            {({ profile }) => (
                <ModalLayout
                    title={i18n.t(
                        'pages.home.manageCard.physicalCardOrdered.title'
                    )}
                    subtitle={i18n.t(
                        'pages.home.manageCard.physicalCardOrdered.subtitle'
                    )}
                >
                    <View>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 28,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.physicalCardOrdered.orderDate'
                            )}
                            &#58;
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {response && dateToday}
                        </Text>
                    </View>
                    <View style={{ marginTop: 30 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.orderPhysicalCard.item2'
                            )}
                            &#58;
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {profile && profile.legal_address.address_line_1}
                        </Text>
                    </View>
                    <View>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {profile && profile.legal_address.city}&#44;
                            {profile && profile.legal_address.state}&#44;
                            {profile && profile.legal_address.postal_code}
                        </Text>
                    </View>
                    <View style={{ marginTop: 30 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.orderPhysicalCard.item4'
                            )}
                            &#58;
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {response && dateAfterTenDays}
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 12,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 20,
                                letterSpacing: 0.4,
                                color: 'rgba(0, 0, 0, 0.6)',
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.orderPhysicalCard.item5'
                            )}
                        </Text>
                    </View>
                    <View style={{ alignItems: 'center', flex: 1 }}>
                        <View
                            style={{
                                position: isWeb() ? 'fixed' : 'absolute',
                                width: width - 50,
                                bottom: 20,
                                marginTop: 30,
                                marginBottom: 15,
                            }}
                        >
                            <Button
                                buttonStyle={theme.styles.buttonStylePrimary}
                                titleStyle={theme.styles.titleStylePrimary}
                                loadingProps={{
                                    color: theme.colors.textHeading,
                                }}
                                title={i18n.t('common.done')}
                                onPress={() =>
                                    navigation.push('Home.ManageCard', {
                                        account,
                                    })
                                }
                            />
                        </View>
                    </View>
                </ModalLayout>
            )}
        </MainContext.Consumer>
    );
};

PhysicalCardOrdered.propTypes = {
    onPress: PropTypes.func,
};
