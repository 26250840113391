import { colors, fontFamilySans } from './colors';
import { StyleSheet } from 'react-native';
export const typography = StyleSheet.create({
    textNote: {
        fontFamily: 'DMSans_400Regular',
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'center',
        letterSpacing: 0.15,
    },
    textLink: {
        fontFamily: 'DMSans_400Regular',
        fontSize: 14,
        lineHeight: 24,
        textAlign: 'center',
        textDecorationLine: 'underline',
    },
    scanProfile: {
        fontFamily: 'DMSans_400Regular',
        color: colors.textLink,
        fontSize: 14,
        lineHeight: 24,
        textAlign: 'center',
    },
    phoneLink: {
        color: colors.mediumBlue,
    },
    lightFontWeight: { fontFamily: 'WorkSans_300Light' },
    regularFontWeight: { fontFamily: 'DMSans_400Regular' },
    mediumFontWeight: { fontFamily: 'DMSans_500Medium' },
    boldFontWeight: { fontFamily: 'DMSans_700Bold' },
});
