import { AuthStateEnum, DefaultProfile, MainContext } from '../../App.context';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { AccountSelection } from '../screens/get-started/account-selection.screen';
import { AddressInfo } from '../screens/get-started/address-info.screen';
import { Disclosures } from '../screens/get-started/disclosures.screen';
import { PersonalInfo } from '../screens/get-started/personal-info.screen';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { DocumentVerification } from '../screens/get-started/document-verification.screen';
import { SignUp } from '../screens/get-started/signup.screen';
import { SignIn } from '../screens/get-started/signin.screen';

const Stack = createNativeStackNavigator();

export const OnboardingNavigator = () => {
    const { authState } = React.useContext(MainContext);

    const shouldRender =
        authState === AuthStateEnum.Customer_Pending_Onboarding;

    const methods = useForm({
        mode: 'onTouched',
        defaultValues: { ...DefaultProfile, productId: '' },
    });

    useEffect(() => methods.reset(), []);

    return (
        shouldRender && (
            <FormProvider {...methods}>
                <Stack.Navigator
                    initialRouteName="Onboarding.PersonalInfo"
                    screenOptions={{
                        headerShown: false,
                    }}
                >
                    <Stack.Screen
                        name="Onboarding.PersonalInfo"
                        component={PersonalInfo}
                    />
                    <Stack.Screen
                        name="Onboarding.AddressInfo"
                        component={AddressInfo}
                    />
                    <Stack.Screen
                        name="Onboarding.DocumentVerification"
                        component={DocumentVerification}
                    />
                    <Stack.Screen
                        name="Onboarding.AccountSelection"
                        component={AccountSelection}
                    />
                    <Stack.Screen
                        name="Onboarding.Disclosures"
                        component={Disclosures}
                    />
                    <Stack.Screen
                        name="Auth.Signup"
                        component={SignUp}
                    />
                    <Stack.Screen
                        name="Auth.SignIn"
                        component={SignIn}
                    />
                </Stack.Navigator>
            </FormProvider>
        )
    );
};
