export const colors = {
    aquamarine: '#51EAA7',
    phthaloBlue: '#00008E',
    textLink: '#FFFFFF',
    greenTea: '#E7FFCA80',
    columbiaBlue: '#CAE2E5',
    mediumBlue: '#1B0FCA',
    textHeader: '#E7FFCA',
    textHeading: '#00008E',
    textPrimaryHeading: '#FFFFFF',
    textBody: '#28254A',
    textNote: '#FAFAFA',
    textError: '#AD1933',
    disabledBg: '#DBDBDB',
    disabledText: '#494767',
    successToast: '#4CAF50',
    dangerToast: '#F44336',
    dangerToastBorder: '#E23E38',
    infoToast: '#CAE8EF',
    infoToastBorder: '#2D95BC',
    tabBackground: '#FFFFFF',
    yellow: '#FFD200',
    shadWhite: '#FFFFFF',
    tabBarInactiveTint: '#6C6C85',
    fontBackground: '#000000',
    shadeBlue: '#6262A6',
};

export const fontFamilySans = {
    regularFontWeight: { fontFamily: 'DMSans_400Regular' },
    mediumFontWeight: { fontFamily: 'DMSans_500Medium' },
    boldFontWeight: { fontFamily: 'DMSans_700Bold' }
};

export const gradients = {
    lightGreen: {
        colors: ['#C1F8E0', '#F1FFE0'],
        start: { x: 0, y: 1 },
        end: { x: 1, y: 0 },
    },
    lightYellow: {
        colors: ['#FFD200', '#FFD200'],
        start: { x: 0, y: 1, B: 2, S: 0 },
        end: { x: 1, y: 0, B: 3, S: 0 },
    },
    lightBlue: {
        colors: [colors.shadWhite, '#FFFEFF'],
        start: { x: 0, y: 1 },
        end: { x: 1, y: 0 },
    },
    lightNewBlue: {
        colors: ['#1F1FFF', '#0930BD'],
        start: { x: 0, y: 1 },
        end: { x: 1, y: 0 },
    },
    lightPurple: {
        colors: ['#DDDAF7', '#F4DFE1'],
        start: { x: 0, y: 1 },
        end: { x: 1, y: 0 },
    },
    landing: {
        colors: [colors.phthaloBlue, '#4271A5', colors.aquamarine],
        locations: [0.0417, 0.5521, 0.966],
        start: { x: 1, y: 0 },
    },
    text: {
        colors: ['#3A0A84', '#4D7AA4'],
        start: { x: 0, y: 0 },
        end: { x: 1, y: 0.5 },
        locations: [0.1242, 0.95],
    },
    blueGreen: {
        colors: ['#181093', '#3A5EA1', '#449FA4'],
        locations: [0.063, 0.51, 0.99],
        start: { x: 0, y: 0.5 },
        end: { x: 1, y: 0.75 },
    },
};

export const shadows = {
    lightGreen: {
        shadowColor: '#C1F8E0',
        shadowOpacity: 0.55,
        shadowRadius: 3,
        shadowOffset: { height: 10, width: 5 },
    },
    lightYellow: {
        shadowColor: '#000000',
        shadowOpacity: 0.15,
        shadowRadius: 3,
        shadowOffset: { height: 6, width: 10 },
    },
    lightBlue: {
        shadowColor: colors.columbiaBlue,
        shadowOpacity: 0.75,
        shadowRadius: 3,
        shadowOffset: { height: 10, width: 5 },
    },
    lightPurple: {
        shadowColor: '#F4DFE1',
        shadowOpacity: 0.75,
        shadowRadius: 3,
        shadowOffset: { height: 10, width: 5 },
    },
    lightWhite: {
        shadowColor: '#E0E0E0',
        shadowOpacity: 0.23,
        shadowRadius: 10,
        shadowOffset: { height: 10, width: 5 },
    },
};
