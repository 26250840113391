import { AuthStateEnum, MainContext } from '../../App.context';
import { KYCFailureScreen } from '../screens/kyc/kyc-failure.screen';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

export const FailedKYCNavigator = () => {
    const { authState, profile } = React.useContext(MainContext);
    const shouldRender =
        authState === AuthStateEnum.Customer_Active &&
        profile.kyc_status === 'REJECTED';

    return (
        shouldRender && (
            <Stack.Navigator
                initialRouteName="KYC.Failure"
                screenOptions={{
                    headerShown: false,
                }}
            >
                <Stack.Screen
                    name="KYC.Failure"
                    options={{ headerShown: false }}
                    component={KYCFailureScreen}
                />
            </Stack.Navigator>
        )
    );
};
