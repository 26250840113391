import {
    childScreenOptions,
    topScreenOptions,
} from '../../navigation/screen-options.component';
import { AccountSummaryScreen } from './account-summary.screen';
import { ActivateCard } from '../accounts/components/cards/activate-card.screen';
import { CreditCardSelection } from '../accounts/components/cards/credit-card-selection.screen';
import { EditAddressesScreen } from '../profile/edit-addresses.screen';
import { HomeScreen } from './home.screen';
import { MakeDepositModal } from './make-deposit.modal';
import { MakeFirstDepositModal } from './make-first-deposit.modal';
import { ManageCardScreen } from './manage-card.screen';
import { LineOfCreditAccount } from '../accounts/components/line-of-credit-account';
import { OpenAccount } from '../accounts/components/open-account.screen';
import { OrderPhysicalCard } from '../accounts/components/cards/order-physical-card.screen';
import { OrderVirtualCard } from '../accounts/components/cards/order-virtual-card.screen';
import { PhysicalCardOrdered } from '../accounts/components/cards/physical-card-ordered.screen';
import { PhysicalCardScreen } from '../accounts/components/cards/physical-card-screen';
import PropTypes from 'prop-types';
import React from 'react';
import { ReviewDepositModal } from './review-deposit.modal';
import { SearchTransactionsScreen } from './search-transactions.screen';
import { VerifyIdentityModal } from '../kyc/verify-identity.modal';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from 'i18n-js';

const Stack = createNativeStackNavigator();

export const HomeStackNavigator = () => (
    <Stack.Navigator>
        <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{
                ...topScreenOptions(),
            }}
        />

        <Stack.Screen
            name="Home.OpenAccount"
            component={OpenAccount}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.accountsTitle'),
            }}
        />

        <Stack.Screen
            name="Home.AccountSummary"
            component={AccountSummaryScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.accountSummary.title'),
            }}
        />
        <Stack.Screen
            name="Home.SearchTransactions"
            component={SearchTransactionsScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.searchTransactions.title'),
            }}
        />
        <Stack.Screen
            name="Home.ManageCard"
            component={ManageCardScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.MakeFirstDeposit"
            options={{
                presentation: 'transparentModal',
                headerShown: false,
                animation: 'fade',
            }}
            component={MakeFirstDepositModal}
        />

        <Stack.Screen
            name="Home.MakeDeposit"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.makeDeposit.title'),
            }}
            component={MakeDepositModal}
        />

        <Stack.Screen
            name="Home.ReviewDeposit"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.makeDeposit.reviewTitle'),
            }}
            component={ReviewDepositModal}
        />

        <Stack.Screen
            name="Home.CreditCardSelection"
            component={CreditCardSelection}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.OrderVirtualCard"
            component={OrderVirtualCard}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.OrderPhysicalCard"
            component={OrderPhysicalCard}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.PhysicalCardOrdered"
            component={PhysicalCardOrdered}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.PhysicalCardDetails"
            component={PhysicalCardScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.ActivateCard"
            component={ActivateCard}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.EditAddress"
            component={EditAddressesScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.profile.addresses'),
            }}
        />

        <Stack.Screen
            name="Home.VerifyIdentity"
            component={VerifyIdentityModal}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.kycInReview.verifyIdentity'),
            }}
        />

        <Stack.Screen
            name="Home.LineOfCreditAccount"
            component={LineOfCreditAccount}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.accountSelection.lineofCreditAccount.headerTitle'),
            }}
        />
    </Stack.Navigator>
);

HomeStackNavigator.propTypes = {
    navigation: PropTypes.object.isRequired,
};
