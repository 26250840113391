import { Button, Text } from 'react-native-elements';
import { Image, useWindowDimensions, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { TextGradient } from '../../../common/ui/elements/text-gradient.element';
import { TextCurrency } from '../../../common/ui/elements/text-currency.element';
import { isWeb } from '../../../common/utils/platform.utils';
import { MaskedText } from 'react-native-mask-text';

export const ExternalCardListScreen = () => {
    const {
        params: { externalAccounts, accounts, selectedExternalAccounts, screenName },
    } = useRoute();
    const navigation = useNavigation();
    const { width } = useWindowDimensions();

    return (
        <ModalLayout>
            <CardAccountTile externalAccounts={externalAccounts} accounts={accounts} selectedExternalAccounts={selectedExternalAccounts} screenName={screenName} />
            <View
                style={externalAccounts.length <= 2 ? {
                    marginTop: 15,
                    marginBottom: 15,
                    bottom: 20,
                    left: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50,
                } : null}
            >
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.linkExternalCard')}
                    onPress={() => navigation.push('MoveMoney.linkExternalCard'/* , { externalAccounts } */)}
                ></Button>
            </View>
        </ModalLayout>
    );
};

const CardAccountTile = ({ externalAccounts, accounts, selectedExternalAccounts, screenName }) => {
    const navigation = useNavigation();

    const accountBalance = { balance: "10000" }

    return (
        externalAccounts?.map((accountItem) => (
            <TouchableOpacity
                key={accountItem.id}
                style={{
                    height: 155,
                    marginBottom: 12,
                    borderRadius: 10,
                }}
                onPress={() => navigation.navigate(screenName, { accounts, selectedExternalAccounts: [accountItem] })}
            >
                <LinearGradient
                    {...theme.gradients.lightBlue}
                    style={{
                        padding: 15,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 10,
                        background: '#FFFFFF',
                    }}
                >
                    <View key={accountItem?.id} style={{ flex: 1, flexDirection: 'column' }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 17,
                                    fontFamily: 'DMSans_700Bold',
                                    lineHeight: 20,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                    marginRight: 10
                                }}
                            >
                                {accountItem?.cardType}
                            </Text>
                            <MaskedText mask="**** **** **** 9999">
                                {accountItem.cardNumber}
                            </MaskedText>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 17,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 20,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                }}
                            >
                                {accountItem?.account_owner_names !== [] ? accountItem.account_owner_names : i18n.t('common.accountHolderName')}
                            </Text>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 17,
                                    fontFamily: 'DMSans_400Regular',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                    marginRight: 5
                                }}
                            >
                                {i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.expiryDate')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 17,
                                    fontFamily: 'DMSans_400Regular',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                }}
                            >
                                10/26
                                {/* {accountItem?.expiryDate} */}
                            </Text>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            {/* Bank Logo */}
                            <Text
                                style={{
                                    fontSize: 17,
                                    fontFamily: 'DMSans_500Medium',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                }}
                            >
                                Chase Bank
                                {/* {accountItem.bank_name} */}
                            </Text>
                        </View>
                    </View>
                </LinearGradient>
            </TouchableOpacity>
        ))
    );
};

CardAccountTile.propTypes = {
    externalAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            nickname: PropTypes.string,
            routing_identifiers: PropTypes.shape({
                bank_name: PropTypes.string,
                number: PropTypes.string,
            }),
        })
    ),
    accounts: PropTypes.array,
    selectedExternalAccounts: PropTypes.array
};
